.asher__tap__to__continue {
  position: absolute;
  bottom: 20vh;
  right: 5%;
  color: #ffff;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
}

.asher__intro__logo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .play__logo {
    width: 20%;
  }
}
