.asher__intro__container__main {
  display: flex;
  justify-content: center;
  z-index: 10;
  .text__label {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      width: 97%;
      margin: auto;
    }
    
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */ 
      width: 95%;
      margin: auto;
    }
    

    height: auto;
    background: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      bottom: 5%;
    }
    
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */ 
      bottom: 1%;
    }
    
    p {
      font-size: 2.2rem;
      max-width: 100%;
      margin-left: 20rem;

      justify-self: flex-end;
    }
  }
  img {
    height: 23rem;
    position: absolute;
    bottom: 1%;
    left: 2%;
    justify-self: flex-start;
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .asher__intro__container__main {
    .text__label {
      height: auto;

      p {
        font-size: 2rem;
        margin-left: 15rem;
      }
    }
    img {
      height: 25rem;
    }
  }
}

@media screen and (max-device-width: 992px) and (orientation: landscape) {
  .asher__intro__container__main {
    .text__label {
      height: auto;

      p {
        font-size: 1.9rem;
        margin-left: 12rem;
      }
    }
    img {
      height: 14rem;
    }
  }
}

@media screen and (max-device-width: 768px) and (orientation: landscape) {
  .asher__intro__container__main {
    .text__label {
      height: auto;

      p {
        font-size: 1.8rem;
        margin-left: 12rem;
      }
    }
    img {
      height: 14rem;
    }
  }
}

@media screen and (max-device-width: 576px) and (orientation: landscape) {
  .asher__intro__container__main {
    .text__label {
      height: auto;

      p {
        font-size: 1.7rem;
        margin-left: 11rem;
      }
    }
    img {
      height: 13rem;
    }
  }
}

@media screen and (max-device-width: 414px) and (orientation: landscape) {
  .asher__intro__container__main {
    .text__label {
      height: auto;

      p {
        font-size: 1.2rem;
        margin-left: 9rem;
      }
    }
    img {
      height: 11rem;
    }
  }
}

@media screen and (max-device-height: 512px) {
  .asher__intro__container__main {
    .text__label {
      p {
        font-size: 12px;
      }
    }
  }
}
.asher__intro__container__main .text__label {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    height: 57px;
  }
  
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */ 
    height: auto;
  }
  bottom: 2%;
  padding: 4px 0px;
}

@media screen and (max-device-width: 320px) and (max-device-height: 568px) {
  .asher__intro__container__main {
    .text__label {
      padding: 4px 0px;
      p {
        font-size: 14px;
      }
    }
  }
}
